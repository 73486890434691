import React from "react";
import version from "../Version/Version";

import "../Styles/About.css";

const Updates = () => {
  return (
    <div className="about-container">
      <div className="about-list">
        {version.map((version, index) => (
          <div key={index} className="about-card">
            <div
              style={{
                fontSize: "12px",
                background: "white",
                borderRadius: "8px",
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                width: "240px",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid black", // Add black border
              }}
            >
              
              <div className="about-title" >Version:{version.version}</div>
              <div style={{marginTop:"4px"}}><b>{version.text}</b></div>
              <div style={{marginTop:"4px"}}>{version.details.technicalStack}</div>
              <div style={{marginTop:"4px"}}>{version.details.pagingUpdate}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Updates;
