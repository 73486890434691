import React, { lazy, Suspense } from 'react';
import '../Styles/Modal.css';

const Projects = lazy(() => import('../Moduler/Projects'));
const Clients = lazy(() => import('../Moduler/Clients'));
const Education = lazy(() => import('../Moduler/Education'));
const Stack = lazy(() => import('../Moduler/Stack'));
const Experience = lazy(() => import('../Moduler/Experience'));
const Contact = lazy(() => import('../Moduler/Contact'));
const Social = lazy(() => import('../Moduler/Social'));
const About = lazy(() => import('../Moduler/About'));
const Blog = lazy(() => import('../Moduler/Blog'));
const Updates = lazy(() => import('../Moduler/Updates'));

const Modal = ({ isOpen, onClose, content }) => {
  
  
    if (!isOpen || !content) return null;
  
    let contentComponent = null;
  
    switch (content.title) {
      case 'Projects':
        contentComponent = <Projects />;
        break;
      case 'Clients':
        contentComponent = <Clients />;
        break;
      case 'Education':
        contentComponent = <Education />;
        break;
      case 'Stack':
        contentComponent = <Stack />;
        break;
      case 'Experience':
        contentComponent = <Experience />;
        break;
      case 'Contact':
        contentComponent = <Contact />;
        break;
      case 'Social':
        contentComponent = <Social />;
        break;
        case 'About':
        contentComponent = <About />;
        break;
        case 'Updates':
        contentComponent = <Updates />;
        break;
        case 'Blog':
        contentComponent = <Blog />;
        break;
      default:
        contentComponent = null;
    }
  
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal" onClick={(e) => e.stopPropagation()}>
          <h2>{content.title}</h2>
          <Suspense fallback={<div>Loading...</div>}>
          <div className="modal-content">
            {contentComponent}
            </div>
          </Suspense>
          <p />
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };


export default Modal;
