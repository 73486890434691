
const version = [
 
  {
    version: '0.1.7',
    text: 'Updated with Project Screens: Technical Stack & Paging for Enhanced Blog Performance',
    details: {
    technicalStack: [
    'Modernized frontend and backend for heightened performance and security.',
    'Streamlined database structure for faster access.'
    ],
    pagingUpdate: [
    'Accelerated page transitions through optimized pagination algorithms.',
    'Preview feature for quick content overview and smoother navigation.'
    ],
    projectScreensUpdate: 'Added project screens for completed projects.'
    }
    }
   
  ]
  
  
      export default version;