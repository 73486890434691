import React, { useState, useEffect } from 'react';
import './App.css';
import logoMo from"./Img/logoMo.png"
import Modal from './Modal/Modal';
import Updates from './Moduler/Updates';
import { FaNewspaper } from "react-icons/fa";
const App = () => {
  
    const links = [
      { id:1 ,text: 'Projects', url: '/project' ,content: 'Projects' },
      { id:2 ,text: 'Clients', url: '/clients' ,content:'Clients'},
      { id:3 ,text: 'Education', url: '/education',content:'Education'},
      { id:4 ,text: 'Experience', url: '/experience',content:'Experience' },
      { id:5 ,text: 'Contact', url: '/contact',content:'Contact' },
      { id:6 ,text: 'Stack', url: '/stack',content:'Stack' },
      { id:7 ,text: 'Social', url: '/social',content:'Social' },
      { id:8 ,text: 'About', url: '/about',content:'About' },
      { id:9 ,text: 'Blog', url: '/blog', content: 'Blog'  },
    ];
    const packageJson = require('../package.json');
    const appVersion = packageJson.version;
    const [linksVisible, setLinksVisible] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', content: '' });
    useEffect(() => {
      const readPosts = JSON.parse(localStorage.getItem('readPosts')) || {};
      const updatedLinks = links.map(link => ({
        ...link,
        isNew: !readPosts[link.id],
      }));
      updateLinksState(updatedLinks);
    }, []);
  
    const updateLinksState = updatedLinks => {
      setLinks(updatedLinks);
      updateLocalStorage(updatedLinks);
    };
  
    const updateLocalStorage = updatedLinks => {
      if (Array.isArray(updatedLinks)) {
        const readPosts = updatedLinks.reduce((acc, link) => {
          acc[link.id] = !link.isNew;
          return acc;
        }, {});
        localStorage.setItem('readPosts', JSON.stringify(readPosts));
      }
    };
  
    const setLinks = updatedLinks => {
      if (Array.isArray(updatedLinks)) {
        const readPosts = updatedLinks.reduce((acc, link) => {
          acc[link.id] = !link.isNew;
          return acc;
        }, {});
        localStorage.setItem('readPosts', JSON.stringify(readPosts));
      }
    };
  
    const handleLinkClick = link => {
      openModal(link.text, link.content);
    
      // Uppdatera hasNewContent till false och spara i localStorage
      updateLinksState(prevLinks => {
        const newLinks = prevLinks.map(l =>
          l.id === link.id ? { ...l, hasNewContent: false } : l
        );
        return newLinks;
      });
    
      setLinks(updatedLinks => {
        const newLinks = updatedLinks.map(l =>
          l.id === link.id ? { ...l, hasNewContent: false } : l
        );
        return newLinks;
      });
    };
  
    const toggleLinks = () => {
      setLinksVisible(!linksVisible);
    };
  
    const openModal = (title, content) => {
      setModalContent({ title, content });
      setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
    };
  
   
  
    return (
      <div className={`app-container ${linksVisible ? 'clicked' : ''}`}>
        <div className="logo" onClick={toggleLinks}>
          <img className="logo" src={logoMo} alt="logo" />
        
        </div>
        
        <div className="circle-links">
       
        {links.map((link, index) => (
          <a
            key={index}
            className={`link link-${index + 1} ${link.isNew ? 'new-post' : ''}`}
            href="#"
            onClick={e => {
              e.preventDefault();
              handleLinkClick(link);
            }}
          >
            {link.text}
            {link.isNew && <FaNewspaper className="new-post-icon" />}
          </a>
        ))}
      </div>
      
        <Modal
          isOpen={modalOpen}
          onClose={closeModal}
          content={modalContent}
        />
      </div>
    );
  };
  
  export default App;